<template>
  <div class="nav-left">
    <div class="tabbars_nav_icn"></div>
    <div class="tabbars_nav_name">{{ tabbarsName }}</div>

  </div>
</template>

<script>
export default {
  
  name: "indexNav",
  props: ['tabbarsName'],
  data() {
    return {}
  }
}
</script>

<style scoped>
.nav-left {
  display: flex;
  margin-top: 5px;
}

.tabbars_nav_icn {
  width: 3px;
  height: 15px;
  margin: 18px 10px 0 0px;
  background-color: #FF7800;
}

.tabbars_nav_name {
  min-width: 80px;
  height: 50px;
  line-height: 50px;
  font-size: 20px;
  font-weight: 300;
  color: #999999;
}

</style>