<template>
  <div>
    <div class="nav">
      <div class="layout-nav">
        <div class="nav-list dis-flex space-between margin-auto-0">
          <div class="nav-list-l dis-flex">
            <div class="nav-list-ls"></div>
            <ul class="head-lists dis-flex">
              <li class="head-navbaac">课程管理</li>
              <li class="head-navba"></li>
              <li class="head-navba"></li>
              <li class="head-navba"></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="nav-img">
      <!-- <img src="../../assets/images/kechengtixi.png" alt="">-->
    </div>

    <!-------------课程中心------------>
    <div class="layout-center margin-auto-0">
      <!-------------主导航------------>
      <div class="main-nav dis-flex">
        <div
          v-for="(pro, index) in curriculum"
          :key="index"
          :class="isactive === index ? 'activeColor' : 'curriculumColor'"
          @click="switchover(pro, index)"
        >
          {{ pro.name }}
        </div>
      </div>
      <!-------------分类课程导航1------------>
      <div class="curriculum-list dis-flex">
        <h3 v-if="gradation[0]">{{ gradation[0].value }}</h3>
        <div class="curriculum-ul dis-flex">
          <div
            v-for="(field, index) in fieldData"
            :key="index"
            :class="fieldactive === index ? 'courseColor' : 'curColor'"
            @click="switField(field.id, index)"
          >
            {{ field.name }}
          </div>
        </div>
      </div>
      <!-------------分类导航2------------>
      <div class="curriculum-list dis-flex">
        <h3 v-if="gradation[1]">{{ gradation[1].value }}</h3>

        <div class="curriculum-ul dis-flex">
          <div
            v-for="(cur, index) in courseData"
            :key="index"
            :class="curactive === index ? 'courseColor' : 'curColor'"
            @click="switCourse(cur.id, index)"
          >
            {{ cur.name }}
          </div>
        </div>
      </div>

      <!-------------分类导航3------------>

      <div v-if="fourData_id != 0" class="curriculum-list dis-flex">
        <h3 v-if="gradation[2]">{{ gradation[2].value }}</h3>

        <div class="curriculum-ul dis-flex">
          <div
            v-for="(fur, index) in fourData"
            :key="index"
            :class="furactive === index ? 'courseColor' : 'curColor'"
            @click="furCourse(fur.id, index)"
          >
            {{ fur.name }}
          </div>
        </div>
      </div>

      <!-------------分类内容------------>
      <indexNav :tabbarsName="tabbarsName" />
      <ul v-if="hourList.length > 0" class="hour">
        <li
          v-for="(h, index) in hourList"
          :key="index"
          @click="tohourDetial(h)"
          class="hour-item dis-flex"
        >
          <div class="hour-number">
            第<span
              style="
                padding: 0 5px;
                color: #ff780b;
                font-size: 20px;
                font-weight: 400;
              "
              >{{ h.serial_no }}</span
            >课
          </div>
          <div class="hour-text">{{ h.title }}</div>
        </li>
      </ul>
      <div v-else class="wushuju">
        <img src="../../assets/images/wushuju.png" alt="" />
        <!--        <p class="wushuju-text">暂无数据</p>-->
      </div>
      <!---------------课程分页------------------------->
      <div class="block" style="text-align: center; margin-top: 10px">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="currentPage"
          :page-size="10"
          :total="pageCounts"
          layout="prev, pager, next, jumper"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/service/api/api";
import indexNav from "@/components/listTitle/indexNav";

export default {
  name: "index",
  components: {
    indexNav,
  },
  data() {
    return {
      // 主课程分类
      isactive: 0,
      goods_date: "",
      curriculum: [],
      curriculum_id: "",
      // 分类学期
      curactive: 0,
      cur_date: "",
      courseData: [],
      courseData_id: "",
      // 分类年级
      fieldactive: 0,
      field_date: "",
      fieldData: [],
      fieldData_id: "",
      // 分类第四级
      furactive: 0,
      fourData_date: "",
      fourData: [],
      fourData_id: "",
      tabbarsName: "目录",
      // 课时列表
      hourList: [],
      // 分页
      currentPage: 1,
      pageCounts: 0,
      page_num: 1,
      gradation: [],
    };
  },
  created() {
    // console.log(this.$route.params)
    this.init();
    this.getlevelname();
  },
  methods: {
    // 初始化接口
    init() {
      api
        .home()
        .then((res) => {
          if (res.errcode === 0) {
            this.curriculum = res.data;
            this.curriculum_id = res.data[0].id;
            this.fieldData = res.data[0].son;
            this.courseData = res.data[0].son[0].son;
            this.courseData_id = res.data[0].son[0].son[0].id;
            this.fourData = res.data[0].son[0].son[0].son;
            this.fourData_id = res.data[0].son[0].son[0].son[0].id;
            if (this.fourData_id == 0) {
              this.detail(this.courseData_id);
            } else {
              this.detail(this.fourData_id);
            }
          } else {
            this.$message({
              message: res.errmsg,
              duration: 2000,
              type: "warning",
            });
          }
        })
        .catch((err) => {});
    },
    // 获取层次名称
    getlevelname() {
      api.getlevelname().then((res) => {
        this.gradation = res.data;
      });
    },
    // 课程详情列表
    detail(id) {
      console.log(this.courseData_id);
      let params = {
        cateid: id,
      };
      console.log(params);
      api.homelist(params).then((res) => {
        this.hourList = res.data;
        // console.log(res)
      });
    },
    // 主课程切换
    switchover(pro, index) {
      // console.log(pro, 8888)
      this.goods_date = pro.id;
      this.isactive = index;
      this.fieldactive = 0;
      this.curactive = 0;
      // console.log(this.fieldactive)
      this.curriculum.forEach((c, index) => {
        // this.fourData = []
        if (pro.id === c.id) {
          console.log(c, 9999);
          this.fieldData = [];
          this.fieldData = c.son ? c.son : [];
          this.courseData = c.son[0].son;
          this.fourData = c.son[0].son[0].son;
          this.fourData_id = c.son[0].son[0].son[0].id;
        }
        console.log(this.fourData_id, this.fourData_id);
      });

      if (this.fourData_id == 0) {
        this.detail(this.courseData[0].id);
      } else {
        this.detail(this.fourData_id);
      }
    },

    // 年级切换
    switField(id, index) {
      this.curactive = 0;
      console.log("年级id", id);
      this.field_date = id;
      this.fieldactive = index;
      this.fieldData.forEach((f, index) => {
        if (id === f.id) {
          console.log(f);
          this.courseData = [];
          this.courseData = f.son;
          console.log(f.son);
          if (f.son) {
            this.courseData_id = f.son[0].id;
            this.fourData = f.son[0].son;
            this.fourData_id = f.son[0].son[0] ? f.son[0].son[0].id : 0;
          } else {
            this.courseData_id = 0;
            this.fourData = {};
            this.fourData_id = 0;
          }
          // this.detail()
          // console.log('年级', this.courseData_id)
        }
      });
      if (this.fourData_id == 0) {
        this.detail(this.courseData_id);
      } else {
        this.detail(this.fourData_id);
      }
    },

    // 学期切换
    switCourse(id, index) {
      this.curactive = 0;
      console.log(id);
      this.courseData_id = id;
      this.cur_date = id;
      this.curactive = index;
      this.courseData.forEach((f, index) => {
        if (id === f.id) {
          console.log(f);
          this.fourData = [];
          this.fourData = f.son;
          this.fourData_id = f.son[0].id;

          console.log("学期", this.fourData_id);
        }
      });
      if (this.fourData_id == 0) {
        this.detail(this.courseData_id);
      } else {
        this.detail(this.detail(this.fourData_id));
      }
    },
    // 四及分类
    furCourse(id, index) {
      console.log();
      this.courseData_id = id;
      this.fourData_date = id;
      this.furactive = index;
      this.detail(id);
    },
    // 分页每页条数
    handleSizeChange(val) {
      // this.page_num = val
      // console.log(val)
      // console.log(`每页 ${val} 条`);
    },
    // 分页当前页
    handleCurrentChange(val) {
      // console.log(val)
      this.page_num = val;
      this.currentPage = val;
      // console.log(`当前页: ${val}`);
    },
    // 跳转课程详情页
    tohourDetial(h) {
      let id = {
        id: h.id,
      };
      api.kcdetail(id).then((res) => {
        if (res.errcode == 0) {
          this.$router.push({
            path: "hourDetial",
            query: {
              id: h.id,
            },
          });
        } else {
          this.$message({
            message: res.errmsg,
            duration: 2000,
            type: "warning",
          });
        }
      });
    },
  },
};
</script>

<style scoped>
body {
  background-color: #fafafa;
}
.nav {
  width: 100%;
  height: 60px;
  background: linear-gradient(90deg, #fff1f1 0%, #fdf9e9 100%);
  opacity: 0.5;
  /*margin-top: -20px;*/
}

.nav-img {
  text-align: center;
  margin: 30px 0;
  cursor: pointer;
}
.nav-img > img {
  height: 80px;
}
/*课程中心*/
.layout-center {
  width: 1200px;
  margin: 0 auto;
  min-height: 300px;
}

.main-nav {
  width: 100%;
  height: 50px;
  line-height: 55px;
  border-bottom: 1px solid #e5e5e5;
}

/*主课程*/
.curriculumColor {
  min-width: 50px;
  line-height: 32px;
  font-size: 20px;
  font-weight: 400;
  color: #666666;
  cursor: pointer;
  margin: 0 70px 0 0;
}

.activeColor {
  min-width: 50px;
  line-height: 32px;
  margin: 0 70px 0 0;
  font-size: 20px;
  font-weight: 500;
  color: #f20d0d;
  cursor: pointer;
}

/*分类课程导航1*/
.curriculum-list {
  width: 100%;
  height: 50px;
  line-height: 50px;
}

.curriculum-list > h3 {
  min-width: 32px;
  font-size: 16px;
  font-weight: 400;
  color: #000000;
  margin-right: 30px;
}

.curriculum-ul {
  min-width: 100px;
  height: 50px;
  line-height: 50px;
  white-space: nowrap;
}

.curColor {
  min-width: 50px;
  height: 32px;
  line-height: 32px;
  font-size: 14px;
  font-weight: 400;
  color: #999999;
  cursor: pointer;
  padding: 0 10px;
  margin: 9px 10px;
}

.courseColor {
  min-width: 50px;
  height: 32px;
  line-height: 32px;
  margin: 9px 10px;
  padding: 0 10px;
  font-weight: 500;
  color: #f20d0d;
  cursor: pointer;
  background: #f2e7e9;
  border-radius: 3px;
}

/*课时*/
.hour {
  height: 700px;
  overflow-y: scroll;
  
}

.hour-item {
  width: 100%;
  height: 80px;
  background: #ffffff;
  box-shadow: 0px 0px 21px 3px rgba(4, 0, 0, 0.05);
  border-radius: 9px;
  margin-bottom: 20px;
  cursor: pointer;
}

.hour-number {
  min-width: 126px;
  padding: 0 30px;
  height: 50px;
  line-height: 50px;
  border-right: 1px solid #eeeeee;
  text-align: center;
  margin: 15px 20px;
  font-size: 20px;
  font-weight: 400;
  color: #999999;
}

.hour-text {
  min-width: 100px;
  height: 50px;
  line-height: 50px;
  font-size: 20px;
  margin: 15px 20px;
  font-weight: 400;
  color: #ff7800;
}

.wushuju {
  width: 235px;
  height: 600px;
  margin: 80px auto;
}

.wushuju > img {
  width: 235px;
  height: 196px;
}

.wushuju-text {
  width: 100%;
  height: 50px;
  line-height: 50px;
  text-align: center;
  color: #999999;
  font-size: 22px;
  margin-top: 20px;
}

.layout-nav {
  width: 100%;
  height: 60px;
  line-height: 60px;
  margin-top: -4px;
}

.nav-list {
  width: 80%;
  height: 60px;
}
.nav-list-l {
  width: 586px;
  /*height: 24px;*/
  font-size: 20px;
  font-weight: bold;
  margin-top: 5px;
  color: #6094ff;
}

.nav-list-ls {
  width: 86px;
  height: 24px;
  font-size: 20px;
  font-weight: bold;
  /*margin-top: 5px;*/
  color: #6094ff;
}

.nav-list-ls > img {
  width: 86px;
  height: 24px;
  margin-top: 16px;
}

.head-navbaac {
  width: 84px;
  height: 60px;
  background: #ffffff;
  font-size: 16px;
  font-weight: 400;
  color: #111111;
  text-align: center;
  cursor: pointer;
  margin: 0 10px;
  border-bottom: 2px solid #ff7800;
}
.head-navba {
  width: 84px;
  height: 60px;
  margin: 0 10px;
}

.head {
  width: 40px;
  height: 40px;
  line-height: 40px;
  margin: 10px 10px 0 0;
  position: relative;
  cursor: pointer;
}

.head > img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.head-name {
  min-width: 42px;
  height: 12px;
  font-size: 14px;
  font-weight: 400;
  color: #333333;
}
</style>